import axios from "../axiosConfig"; // Adjust the import path accordingly

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;


var WebApiUrl = express_server_url,
WebApiUrl = express_server_url.replace("localhost", window.location.hostname)

const MiscellaneousMaster = {

  GetMiscellaneousMaster: async (controlType) => {
    try {
      const apiUrl = `${WebApiUrl}/api/MiscellaneousMaster/GetMiscellaneous?ControlType=${controlType}`;
      const response = await axios.post(
        apiUrl,
        {
          filter: [],
          order: [
            {
              propertyName: "string",
              ascending: true
            }
          ],
          pageNumber: 0,
          pageSize: 5000
        },
        {
          withCredentials: true,
        }
      );
      // console.log(apiUrl);
      // console.log("Response:", response.data.result);
      return response.data.result;
      // setMainDataCallback(response.data.result);
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  InsertMiscellaneousMaster: async (data) => {
    try {
      // console.log(data);
      const apiUrl = `${WebApiUrl}/api/MiscellaneousMaster`;
      const response = await axios.post(
        apiUrl,
        {
          controlType: Number(data.controlType),
          name: data.name,
          printName: data.printName,
          availableFrom: data.availableFrom + ":00",
          availableTo: data.availableTo + ":00",
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      // console.log("Response:", response.data.result);
      return true;
      // setMainDataCallback(response.data.result);
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  GetMiscellaneousMasterById: async (MiscellaneousMasterId, ControlType) => {
    try {
      const apiUrl = `${WebApiUrl}/api/MiscellaneousMaster?MiscellaneousMasterId=${MiscellaneousMasterId}&ControlType=${ControlType}`;
      const response = await axios.get(apiUrl, {}, {
        withCredentials: true,
      });
      // console.log('Response:', response.data.result);
      return response.data.result;
    } catch (error) {
      console.error('Error:', error.message);
    }
  },

  deleteMiscellaneousMaster: async (MiscellaneousMasterId, ControlType) => {
    try {
      // console.log("delete");
      const apiUrl = `${WebApiUrl}/api/MiscellaneousMaster?MiscellaneousMasterId=${MiscellaneousMasterId}&ControlType=${ControlType}`;
      const response = await axios.delete(apiUrl, {
        withCredentials: true,
      });
      // console.log("Delete Response:", response.data.result);
      return true;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  updateMiscellaneousMaster: async (data) => {
    try {
      const apiUrl = `${WebApiUrl}/api/MiscellaneousMaster?MiscellaneousMasterId=${data.id}&ControlType=${data.controlType}`;
      // console.log(data);
      const response = await axios.put(
        apiUrl,
        {
          id: data.id,
          controlType: Number(data.controlType),
          name: data.name,
          printName: data.printName,
          availableFrom: data.availableFrom,
          availableTo: data.availableTo,
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      // console.log("Response:", response.data.result);
      return response.data.isSuccess;

      // setMainDataCallback(response.data.result);
    } catch (error) {
      console.error("Error:", error.message);
    }
  },
};

export default MiscellaneousMaster;
