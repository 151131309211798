import React, { useEffect, useState } from "react";
import MyAccountComponent from "../Component/MyAccountComponent";
import MyAccountComponent2 from "../Component/MyAccountComponent2";
import MyAccountComponent3 from "../Component/MyAccountComponent3";
import LogOut from "../Component/LogOut";
import Account from "../API/API_SERVISES/Account";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../Component/Loader";

const MyAccountPage = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const loginDataString = localStorage.getItem("accountLogin");
    if (!loginDataString)
      navigate("/")
  }, [])

  const location = useLocation();
  const [queryString, setQueryString] = useState(location.search);

  if (queryString === "") {
    setQueryString("?MyAccount")
  }

  const [box1, setBox1] = useState(false);
  const [box2, setBox2] = useState(false);
  const [box3, setBox3] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (queryString === "?MyAccount") {
      setBox1(true);
      setBox2(false);
      setBox3(false);
    } else if (queryString === "?MyAddress") {
      setBox1(false);
      setBox2(true);
      setBox3(false);
    } else if (queryString === "?MyOrder") {
      setBox1(false);
      setBox2(false);
      setBox3(true);
    }
  }, [queryString]);

  const openbox1 = () => {
    setQueryString("?MyAccount");
  };
  const openbox2 = () => {
    setQueryString("?MyAddress");
  };
  const openbox3 = () => {
    setQueryString("?MyOrder");
  };

  const logoutBtn = () => {
    setLogoutModal(!logoutModal);
  };

  const logout = () => {
    localStorage.clear();
    navigate("/");
    window.location.reload();
  };

  const [accountData, setAccountData] = useState([]);

  useEffect(() => {
    const loginDataString = localStorage.getItem("accountLogin");
    const loginData = JSON.parse(loginDataString);

    const fetchAccountData = async () => {
      try {
        const data = await Account.getAccountById(loginData.d1Id);
        if (data) {
          setLoading(false);
          setAccountData(data.item1[0]);
        }
      } catch (error) {
        console.error("Error fetching account data: ", error);
        setLoading(false);
      }
      setLoading(false);
    };

    fetchAccountData();
  }, []);

  return (
    <div className="myaccountpage">
      <div className="myaccountpage-left">
        <div className="product-category-head"> My Account </div>
        <div
          onClick={openbox1}
          style={{
            borderBottom: "1px solid rgb(228, 228, 228)"
            , backgroundColor: queryString === "?MyAccount" ? "rgb(235 235 235)" : "white"
          }}
          className="catogory-box"
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <i
              className="fa-regular fa-user fs-b"
              style={{ marginRight: "10px" }}
            ></i>
            <p className="product-category"> Personal Info </p>
          </div>
          <i className="fa-solid fa-chevron-right fs-b"></i>
        </div>

        <div
          onClick={openbox2}
          style={{
            borderBottom: "1px solid rgb(228, 228, 228)"
            , backgroundColor: queryString === "?MyAddress" ? "rgb(235 235 235)" : "white"
          }}
          className="catogory-box"
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <i
              className="fa-solid fa-location-dot fs-b"
              style={{ marginRight: "10px" }}
            ></i>
            <p className="product-category">Addresses </p>
          </div>
          <i className="fa-solid fa-chevron-right fs-b"></i>
        </div>

        <div
          onClick={openbox3}
          style={{
            borderBottom: "1px solid rgb(228, 228, 228)"
            , backgroundColor: queryString === "?MyOrder" ? "rgb(235 235 235)" : "white"
          }}
          className="catogory-box"
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <i
              className="fa-solid fa-burger fs-b"
              style={{ marginRight: "10px" }}
            ></i>
            <p className="product-category"> My Orders & Feedback </p>
          </div>
          <i className="fa-solid fa-chevron-right fs-b"></i>
        </div>

        <div
          onClick={logoutBtn}
          style={{ borderBottom: "1px solid rgb(228, 228, 228)" }}
          className="catogory-box"
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <i
              className="fa-solid fa-arrow-right-from-bracket fs-b"
              style={{ marginRight: "10px" }}
            ></i>
            <p className="product-category">Log Out </p>
          </div>

          {/* <i className="fa-solid fa-chevron-right fs-b"></i> */}
        </div>
      </div>
      <div className="myaccountpage-right">

        {box1 && <MyAccountComponent accountData={accountData} />}
        {box2 && <MyAccountComponent2 accountData={accountData} />}
        {box3 && <MyAccountComponent3 accountData={accountData} />}


        {logoutModal && <LogOut close={logoutBtn} logout={logout} text="You Want To Logout" />}
      </div>
      {loading && <Loader />}
    </div>
  );
};

export default MyAccountPage;
