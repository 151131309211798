import React, { useContext, useEffect, useState } from "react";
import MainModalComponent from "./MainModalComponent";
import MainModalComponent2 from "./MainModalComponent2";
import order from "../API/API_SERVISES/Order";
import Loader from "./Loader";
import MainModalComponent3 from "./MainModalComponent3";
import DeliveryAndTimeSlot from "../API/API_SERVISES/DeliveryAndTimeSlot";
import MyContext from "./MyContext";

const MainModal = (props) => {
  const [serviceType, setServiceType] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [selectedService, setSelectedService] = useState();
  const [AllottedTimeSlot, setAllottedTimeSlot] = useState([]);
  const { CompanyDetail, selectedService, setSelectedService, setOrderType } = useContext(MyContext);

  const [getDeliveryAndTimeSlotId, setDeliveryAndTimeSlotId] = useState({
    deliveryPointName: 1,
    orderType: 752,
    distance: 0,
    // distanceFrom: 0,
    isCurrentDate: true,
  });

  useEffect(() => {
    // console.log("Updated deliveryAndTimeSlotId:", getDeliveryAndTimeSlotId);
  }, [getDeliveryAndTimeSlotId]);

  // console.log(selectedService);
  useEffect(() => {
    const fetchdata = async () => {
      const data = await order.getServiceType();
      // console.log(data);
      setServiceType(data);
      console.log("serviceType", data);
      // If you want to set the initial selectedService based on the first item, do it here
      setLoading(false)
      if (data.length > 0 && selectedService === undefined) {
        setSelectedService(`${data[0].id}`);
        localStorage.setItem("ServiceType", `${data[0].id}`);
      }
      else if (selectedService === 752) {
        setSelectedService(752);
        localStorage.setItem("ServiceType", 752);
        // alert("else")
      }
      else {
        var isFromOrderTaker = localStorage.getItem("OrderTaker");
        if (isFromOrderTaker == "Yes") {
          setSelectedService(751);
          localStorage.setItem("ServiceType", 751);
        }
        else {
          setSelectedService(752);
          localStorage.setItem("ServiceType", 752);
        }
      }
      const updatedValues = {
        ...getDeliveryAndTimeSlotId,
        orderType: selectedService,
        deliveryPointName: 1,
        isCurrentDate: true
      };
      getDeliveryTimeSlots(updatedValues)
    };
    fetchdata();
  }, []);

  const handleServiceChange = async (event) => {
    const newServiceId = event.target.id;
    // alert(typeof (newServiceId))
    setSelectedService(newServiceId);
    localStorage.setItem("ServiceType", newServiceId);
    if (newServiceId == 752) {
      setOrderType("Home delivery")
    } else if (newServiceId == 753) {
      setOrderType("Pick Up")
    } else if (newServiceId == 751) {
      setOrderType("Table Order")
    } else if (newServiceId == undefined) {
      setOrderType("")
      // setSelectedService()
    }

    setDeliveryAndTimeSlotId((prevState) => ({
      ...prevState,
      orderType: newServiceId,
      deliveryPointName: 1,
      isCurrentDate: true
    }));

    const updatedValues = {
      ...getDeliveryAndTimeSlotId,
      orderType: newServiceId,
      deliveryPointName: 1,
      isCurrentDate: true
    };
    getDeliveryTimeSlots(updatedValues)
    //console.log(getDeliveryAndTimeSlotId);
  };
  const getDeliveryTimeSlots = async (updatedValues) => {
    try {
      setLoading(true);
      const deliverySlot = await DeliveryAndTimeSlot.getAllottedDeliveryTimeSlots(updatedValues);

      const data = deliverySlot.filter((data, index, self) =>
        index === self.findIndex((t) => (
          t.id === data.id
        ))
      );
      console.log(data);

      if (data) {
        setAllottedTimeSlot(data);
        setLoading(false);
      } else {
        setLoading(false);
        setAllottedTimeSlot("");
        //alert("No data found");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching time slots:", error);
      // Handle the error appropriately, e.g., show an error message to the user
    }
  }

  const getServiceTypeId = () => {
    localStorage.setItem("ServiceType", selectedService);
    props.closemainmodal();
    props.fetchData()
  };
  console.log(selectedService)

  const [isFromOrderTkr, setIsFromOrderTkr] = useState();

  useEffect(() => {
    var isFromOrderTak = localStorage.getItem("OrderTaker");
    setIsFromOrderTkr(isFromOrderTak);
  }, [])

  const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) {
      month = `0${month}`;
    }
    if (day < 10) {
      day = `0${day}`;
    }

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const convertToIsoFormat = (dateStr) => {
    const [day, month, year] = dateStr.split('/');
    return `${year}-${month}-${day}`;
  };

  const ChangeDeliveryDate = async (e) => {
    const selectedIsoDate = e.target.value;

    localStorage.setItem("deliverydate", selectedIsoDate)

    const currentDate = await getCurrentDate();
    // alert(currentDate == convertToIsoFormat(selectedIsoDate))
    var isCrntDate;
    if (currentDate === convertToIsoFormat(selectedIsoDate))
      isCrntDate = true
    else
      isCrntDate = false

    let updatedValues = {
      ...getDeliveryAndTimeSlotId,
      orderType: selectedService,
      isCurrentDate: isCrntDate,
    };
    setDeliveryAndTimeSlotId((prevState) => ({
      ...prevState,
      orderType: selectedService,
      isCurrentDate: isCrntDate,
    }));
    console.log(updatedValues);
    getDeliveryTimeSlots(updatedValues);
  };

  return (
    <div className="modal-back">
      <div className="main-modal-mothers-hut">
        <div className="xmark-ms-2" onClick={props.closemainmodal}>
          <i className="fa-solid fa-xmark"></i>
        </div>
        <i
          onClick={props.closemainmodal}
          style={{ color: "white", cursor: "pointer" }}
          className="fa-solid fa-xmark card-menu-favorite dis-non"
        ></i>
        <div className="main-modal-section-1">
          <div className="">Service Type</div>
          <div className="section-1-flex">
            {serviceType &&
              Array.isArray(serviceType) &&
              serviceType.length > 0 ? (
              serviceType
                .filter(type => {
                  return (
                    (type.name !== "Home Delivery" || CompanyDetail.isEnableHomeDelivery) &&
                    (type.name !== "Pick up" || CompanyDetail.isEnablePickUp) &&
                    // (type.name !== "Table Order" || CompanyDetail.isAllowTblOptionForGuest) &&
                    type.name !== "POS"
                  );
                })
                .map(type => {
                  // alert(type.id)
                  if (type.id === 751) {
                    if (CompanyDetail.isAllowTblOptionForGuest || isFromOrderTkr === "Yes") {
                      return (
                        <div
                          key={type.id}
                          onClick={() => handleServiceChange({ target: { id: `${type.id}` } })}
                        >
                          <input
                            type="radio"
                            name="serviceType"
                            id={`${type.id}`}
                            checked={selectedService == `${type.id}`}
                            onChange={handleServiceChange}
                          />
                          {type.name}
                        </div>
                      );
                    }
                    return null;
                  }
                  return (
                    <div
                      key={type.id}
                      onClick={() => handleServiceChange({ target: { id: `${type.id}` } })}
                    >
                      <input
                        type="radio"
                        name="serviceType"
                        id={`${type.id}`}
                        checked={selectedService == `${type.id}`}
                        onChange={handleServiceChange}
                      />
                      {type.name}
                    </div>
                  );
                })
            ) : null
            }
          </div>
        </div>

        {serviceType.length > 0 &&
          selectedService == serviceType[1]?.id && (
            <MainModalComponent2
              heading1={"Delivery address"}
              heading2={"Delivered On"}
              loading={loading}
              setLoading={setLoading}
              AllottedTimeSlot={AllottedTimeSlot}
              ChangeDeliveryDate={ChangeDeliveryDate}
            />
          )}

        {serviceType.length > 1 &&
          selectedService == serviceType[2]?.id && (
            <MainModalComponent3
              heading1={"Pickup Location"}
              heading2={"Pick-up On"}
              loading={loading}
              setLoading={setLoading}
              AllottedTimeSlot={AllottedTimeSlot}
              ChangeDeliveryDate={ChangeDeliveryDate}
            />
          )}

        {serviceType.length > 2 &&
          selectedService == serviceType[0]?.id && (
            <MainModalComponent
              heading1={" Table Order Location"}
              heading2={"Book Table On"}
              loading={loading}
              setLoading={setLoading}
              AllottedTimeSlot={AllottedTimeSlot}
              ChangeDeliveryDate={ChangeDeliveryDate}
            />
          )}

        <button onClick={getServiceTypeId} className="main-modal-button-1">
          DONE
        </button>
      </div>
      {loading && <Loader />}
    </div>
  );
};

export default MainModal;
