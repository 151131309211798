import React, { useState } from "react";
import OrderBox from "./OrderBox";
import Loader from "./Loader";

const MyAccountComponent3 = (props) => {
  const [loading, setLoading] = useState(true);

  return (
    <div>
      {" "}
      <div
        style={{ display: "flex", //height: "90vh", 
        justifyContent: "center" }}
        className="master-container"
      >
        <div
          style={{ height: "75vh", borderRadius: "15px" }}
          className="card-cart cart height"
        >
          <label className="title">My Orders & Feedback</label>
          <OrderBox setLoading={setLoading} />
        </div>
      </div>
      {loading && <Loader />}
    </div>
  );
};

export default MyAccountComponent3;

