import axios from "../axiosConfig"; // Adjust the import path accordingly
//import AccountGroupApi from './AccountGroup';
//import StateInfoApi from './StateInfo';
//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const ShopBanner = {

  //**************************************************Get All Shop Banner *********************************** */
  GetShopBanners: async () => {
    try {
      const apiUrl = `${WebApiUrl}/api/ShopBanner/GetShopBanners`;
      const response = await axios.post(apiUrl, 
        {
          filter: [],
          order: [
            {
              propertyName: "string",
              ascending: true
            }
          ],
          pageNumber: 0,
          pageSize: 5000
        },
        {
        withCredentials: true,
        credentials: "include",
      });
      console.log("Response:", response.data);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

};

export default ShopBanner;
