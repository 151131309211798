import axios from '../axiosConfig'; // Adjust the import path accordingly

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const OTPinfo = {

    // ************************* Send OTP ***************************
    SendOtp: async (data) => {
        debugger
        try {
            const apiUrl = `${WebApiUrl}/api/SendOTP/SendOTPNewAcc`;
            const response = await axios.post(apiUrl, {
                MobileNo: data.aMobileNo,
                EmailId: data.aEmail
            },
                {
                    withCredentials: true,
                });
            debugger
            console.log('Response:', response.data.result);
            return response.data.result;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },


    SendOtpForForgotPassword: async (data) => {
        console.log(data)
        debugger;
        var checkType = ""
        if (data.aEmail.includes('@')) {
            checkType = "Email"
        }
        else if (/^[\d\s-()+]+$/.test(data.aEmail)) {
            checkType = "MobileNo"
        }
        else {
            alert("Enter Valid Email/Mobile");
            return false;
        }

        let requestData = {};

        if (checkType === "Email") {
            requestData = {
                MobileNo: "",
                EmailId: data.aEmail
            };
        } else if (checkType === "MobileNo") {
            requestData = {
                MobileNo: data.aEmail,
                EmailId: ""
            };
        }
        try {
            const apiUrl = `${WebApiUrl}/api/SendOTP`;
            const response = await axios.post(apiUrl, requestData,
                {
                    withCredentials: true,
                });
            // console.log('Response:', response.data.result);
            return response.data.result;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    VerifyOtp: async (DataSignin, DataOTP) => {
        debugger
        try {
            const apiUrl = `${WebApiUrl}/api/SendOTP/VerifyOtp`;
            const response = await axios.post(apiUrl, {
                otp: Number(DataOTP.otp),
                mobileNo: DataSignin.aMobileNo,
                emailId: DataSignin.aEmail
            },
                {
                    withCredentials: true,
                });
            console.log('Response:', response.data.result);
            return response.data.result;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },
};

export default OTPinfo;
