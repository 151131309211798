import React from 'react'

const ForgotPasswordPanel = (props) => {
  const { handleInputChangeSignIn, inputValuesForSignIn,
    VerifyOTP,
    inputValuesForOTP,
    secondsLeft,
    handleInputChangeOTP,
    isDisabled,
    handleClick,
    visibleResendOTP,
    SendOtpForForgotPassword,

  } = props
  console.log(isDisabled);
  return (
    <div className="form-container">
      <p className="title" style={{ margin: "0" }}>
        Forgot password
      </p>
      <form className="form" style={{ marginTop: "0px" }}>
        <div className="input-group" style={{ marginBottom: "10px" }}>
          <label htmlFor="password">Email/Mobile</label>
          <div className="" style={{ display: "flex" }}>
            <input
              type="text"
              onChange={handleInputChangeSignIn}
              value={inputValuesForSignIn.aEmail}
              name="aEmail"
              placeholder=""
              disabled={isDisabled}
            />
            <p className="sendOtpButton" style={{ cursor: "pointer", backgroundColor: isDisabled ? "#a5a5a5" : "#a78bfa" }} disabled={isDisabled} onClick={SendOtpForForgotPassword}>Send OTP</p>
          </div>
        </div>
        {visibleResendOTP && (
          <button onClick={handleClick} style={{ color: isDisabled ? '#947bdd' : 'rgba(156, 163, 175, 1)' }} className="forgot resendotpbtn" disabled={isDisabled}>
            {isDisabled ? `Resend OTP after ${secondsLeft} sec` :
              <span>Haven't received the OTP ?
                <span style={{ color: '#a78bfa', textDecoration: "underline" }}>Resent OTP</span></span>}
          </button>
        )}

        <div className="input-group">
          <label htmlFor="password">OTP</label>
          <input
            type="number"
            onChange={handleInputChangeOTP}
            value={inputValuesForOTP.otp}
            name="otp"
            placeholder=""
          />
        </div>
        <div
          style={{
            marginTop: "15px",
            cursor: "pointer",
            marginBottom: "10px",
          }}
          className="sign"
          onClick={VerifyOTP}
        >
          Verify OTP
        </div>
      </form>
    </div>
  )
}

export default ForgotPasswordPanel