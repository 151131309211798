import React, { useEffect, useState } from "react";

const PaymentModeSelection = (props) => {
  const { handlePayment, payment, cod, pod, ClosePayment, totalAmount } = props;
  const [isScreenLarge, setIsScreenLarge] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsScreenLarge(window.innerWidth < 800);
    };
    window.addEventListener('resize', handleResize);
    // Cleanup function to remove the event listener when component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);

    };
  }, []);

  //console.log("isScreenLarge",isScreenLarge)

  return (
    <div className="cartpage-right-section-3">
      <div
        onClick={ClosePayment}
        style={{ zIndex: "2", background: "gray", color: "white" }}
        className="xmark-ms-2"
      >
        <i className="fa-solid fa-xmark"></i>
      </div>
      <label style={{ border: "0" }} className="title dis-fl-fl-co">
        Payment
      </label>
      <p
        style={{ margin: "0", fontWeight: "600", color: "gray" }}
        className="f-12"
      >
        Online payment recommended to reduce cash payment
      </p>
      <div className="cartpage-service-options-box">
        <div
          className="cartpage-service-options-1"
          onClick={() => handlePayment({ target: { id: "0" } })}
        >
          <div className="cartoage-input-box-1">
            <input
              type="radio"
              name="delivery"
              id="0"
              checked={payment === "0"}
              onChange={handlePayment}
            />
            <div>
              <p style={{ margin: "0" }} className="f-14">
                Pay on Delivery
                <img
                  style={{
                    width: "20px",
                    marginLeft: "10px",
                    marginBottom: "-5px",
                  }}
                  src={cod}
                  alt=""
                />
              </p>
              <p style={{ margin: "3px 0px" }} className="f-12">
                Cash Please keep exact change handy to help us serve you better.
              </p>
            </div>
          </div>
        </div>
        <div
          className="cartpage-service-options-1"
          onClick={() => handlePayment({ target: { id: "1" } })}
        >
          <div className="cartoage-input-box-1">
            <input
              type="radio"
              name="online"
              id="1"
              checked={payment === "1"}
              onChange={handlePayment}
            />
            <div>
              <p style={{ margin: "0" }} className="f-13">
                Pay Online{" "}
                <img
                  style={{
                    width: "20px",
                    marginLeft: "10px",
                    marginBottom: "-5px",
                  }}
                  src={pod}
                  alt=""
                />
              </p>

              <p style={{ margin: "3px 0px" }} className="f-12">
                Cash Please keep exact change handy to help us serve you better.
              </p>
            </div>
          </div>
        </div>
      </div>
      {isScreenLarge && (
        <div
          style={{ margin: "20px 0px", borderRadius: "12px" }}
          className="myordercart-mobile-2"
          onClick={ClosePayment}
        >
          Select Payment Mode
          <div>
            <i
              style={{ marginLeft: "5px" }}
              className="fa-solid fa-indian-rupee-sign"
            ></i>{" "}{totalAmount}

          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentModeSelection;
