import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import lowericonbox from "../Assets/grid.png"

const MobileBottomMentBtn = (props) => {
  const handleGoBack = () => {
    window.history.back();
  };

  const [buttonColors, setButtonColors] = useState({
    defaultColor: "",
    ourMenuColor: "",
    myCartColor: ""
  });

  const currentPath = window.location.pathname;
  console.log(currentPath);

  useEffect(() => {
    // Update button colors based on the URL path
    if (currentPath === "/Ourmenu") {
      setButtonColors({
        defaultColor: "",
        ourMenuColor: "ourMenuColor",
        myCartColor: ""
      });
    } else if (currentPath === "/Mycart") {
      setButtonColors({
        defaultColor: "",
        ourMenuColor: "",
        myCartColor: "myCartColor"
      });
    } else if (currentPath === "/") {
      setButtonColors({
        defaultColor: "defaultColor",
        ourMenuColor: "",
        myCartColor: ""
      });
    }
    else {
      setButtonColors({
        defaultColor: "",
        ourMenuColor: "",
        myCartColor: ""
      });
    }
  }, [currentPath]);

  return (
    <div className="button-container-bottom">
      <Link to="/" className={`button-bottom-nav ${buttonColors.defaultColor}`}>
        <div className='mobileMenu'>
          <i className="fa-solid fa-house" style={{ textAlign: "center" }}></i>
          <label style={{ fontSize: "10px", marginTop: '2px' }}>Home</label>
        </div>
      </Link>
      <Link to="/Ourmenu" className={`button-bottom-nav ${buttonColors.ourMenuColor}`}>
        <div className='mobileMenu'>
          <i className="fa-solid fa-list" style={{ textAlign: "center" }}></i>
          <label style={{ fontSize: "10px", marginTop: '2px' }}>Menu</label>
        </div>
      </Link>

      <Link to="/Mycart" className={`button-bottom-nav ${buttonColors.myCartColor}`} style={{ position: "relative" }}>
        <div className='mobileMenu'>
          <div>
            <i className="fa-solid fa-cart-shopping" style={{ textAlign: "center" }}></i>
            <div className='cart-number-phone'>
              {props.cartNumber === 0 ? <div></div> : props.cartNumber}
            </div>
          </div>
          <label style={{ fontSize: "10px", marginTop: '-3px' }}>Cart</label>
        </div>
      </Link>

      <div onClick={handleGoBack} className="button-bottom-nav">
        <div className='mobileMenu'>
          <i className="mobile-back-btn fa-solid fa-arrow-left-long" style={{ textAlign: "center", fontSize: "18px" }}></i>
          <label style={{ fontSize: "10px", marginTop: '2px' }}>Back</label>
        </div>
      </div>

    </div>
  );
}

export default MobileBottomMentBtn;
