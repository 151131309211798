import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import AddAddress from '../API/API_SERVISES/AddAddress';

const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const mapContainerStyle = {
  width: '100%',
  height: '400px'
};
const libraries = ['places'];

const GoogleMapPage = ({ setTriggerUseEffect }) => {
  const [selectedAddress, setSelectedAddress] = useState('');
  const [distance, setDistance] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currentCoordinates, setCurrentCoordinates] = useState(null);
  const [locationConfirmed, setLocationConfirmed] = useState(false);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyA3Z3M4ymJUaQnTnD5ZD8lLbGbXntTKLHI',
    libraries
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { latitude, longitude } = await getLocation();
        fetchDistance(latitude, longitude);
      } catch (error) {
        console.error('Error fetching location:', error);
      }
    };
    fetchData();
  }, []);

  const getLocation = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setCurrentCoordinates({ lat: latitude, lng: longitude });
            resolve({ latitude, longitude });
          },
          (error) => {
            console.error('Error getting location:', error);
            reject(error);
          }
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
        reject(new Error('Geolocation not supported'));
      }
    });
  };

  const openGoogleMaps = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const confirmLocation = async () => {
    if (currentCoordinates) {
      console.log('Selected Coordinates:', currentCoordinates);
      closeModal();
    } else {
      console.error('No location selected.');
      return;
    }

    const addressString = distance.rows[0].elements[0].distance.value;
    console.log(distance.destination_addresses[0]);

    const addressss = distance.destination_addresses[0];

    const words = addressss.split(/\s+/);
    const pinCode = words[words.length - 2];
    const pc = pinCode.split(',')

    const parts = addressss.split(',');
    const pincodeIndex = parts.length - 2;
    const addressExceptPinCode = parts.slice(0, pincodeIndex + 1).join(',');

    localStorage.setItem("googleMapData", JSON.stringify(distance));
    setTriggerUseEffect(true);
    setLocationConfirmed(true);
    // alert(currentCoordinates.lat)
    // alert(currentCoordinates.lng)  

    const storedArrayString = localStorage.getItem('accountLogin');
    if (storedArrayString) {
      try {
        const loginUser = JSON.parse(storedArrayString);

        const addressData = {
          name: loginUser.name,
          mobile: loginUser.mobileNo,
          pincode: pc[0],
          state: 0,
          address: addressExceptPinCode,
          locality: "",
          city: "",
          latitude: currentCoordinates.lat,
          longitude: currentCoordinates.lng,
          typeOfAddress: 0,
          isDefault: true,
        };
        await AddAddress.insertAddress(addressData, loginUser.d1Id);
      } catch (error) {
        console.error("Error inserting address:", error);
      }
    } else {
      const addressData = {
        name: "",
        mobile: "",
        pincode: pc[0],
        state: 0,
        address: addressExceptPinCode,
        locality: "",
        city: "",
        latitude: currentCoordinates.lat,
        longitude: currentCoordinates.lng,
        typeOfAddress: 0,
        isDefault: true,
      };

      localStorage.setItem("DefaultAddress", JSON.stringify(addressData));
    }
  };


  const handleSelectedAddressChange = (address) => {
    setSelectedAddress(address);
  };

  const handleSelect = async (address) => {
    setSelectedAddress(address);
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      setCurrentCoordinates(latLng);
      fetchDistance(latLng.lat, latLng.lng);
    } catch (error) {
      console.error('Error selecting address:', error);
    }
  };

  const fetchDistance = async (lat, lng) => {
    try {
      // const apiUrl = `${express_server_url}/api/map/fetchDistance?origin=${currentCoordinates.lat},${currentCoordinates.lng}&destination=${lat},${lng}`;
      const apiUrl = `${WebApiUrl}/api/map/fetchDistance?origin=${`23.3698269`},${"88.4821275"}&destination=${lat},${lng}`;
      const response = await axios.post(
        apiUrl,
        {},
        {
          withCredentials: true,
        }
      );
      setDistance(response.data);
      // alert(response.data.rows[0].elements[0].distance.value)
    } catch (error) {
      console.error('There was a problem with your fetch operation:', error);
    }
  };

  const onMapClick = (event) => {
    const { latLng } = event;
    const latitude = latLng.lat();
    const longitude = latLng.lng();
    setCurrentCoordinates({ lat: latitude, lng: longitude });
    fetchDistance(latitude, longitude);
  };

  const renderMap = () => {
    return (
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={14}
        center={currentCoordinates}
        onClick={onMapClick}
      >
        {currentCoordinates && <Marker position={currentCoordinates} />}
      </GoogleMap>
    );
  };

  if (loadError) return 'Error loading maps';
  if (!isLoaded) return 'Loading Maps...';

  return (
    <div>
      <div className="input-container">
        <PlacesAutocomplete
          value={selectedAddress}
          onChange={handleSelectedAddressChange}
          onSelect={handleSelect}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              <input
                {...getInputProps({
                  placeholder: '⊕ Enter your delivery Address',
                  className: 'input-with-image'
                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const style = {
                    backgroundColor: suggestion.active ? '#41b6e6' : '#fff'
                  };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        style
                      })}
                    >
                      {suggestion.description}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
        <button className="input-btn" onClick={openGoogleMaps}>
          Change Address
        </button>
      </div>

      {showModal && (
        <div className="modal-back1">
          <div className="map-modal">
            <div style={{ width: '100%', top: '0%' }}>
              <div
                onClick={closeModal}
                style={{ width: 'auto', display: 'flex', justifyContent: 'flex-end' }}
              >
                <i className="fa-solid fa-xmark" style={{ color: 'black', fontSize: '16px' }}></i>
              </div>
              <div style={{ width: '100%', padding: '10px' }}>
                <PlacesAutocomplete
                  value={selectedAddress}
                  onChange={handleSelectedAddressChange}
                  onSelect={handleSelect}
                >
                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div>
                      <input
                        {...getInputProps({
                          placeholder: 'Search address',
                          className: 'input-with-image'
                        })}
                        style={{ width: "85%" }}
                      />
                      <div className="autocomplete-dropdown-container" style={{ position: "absolute", zIndex: "100" }}>
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const style = {
                            backgroundColor: suggestion.active ? '#41b6e6' : '#fff',
                            padding: "10px",
                          };
                          return (
                            <div {...getSuggestionItemProps(suggestion, { style })}>
                              {suggestion.description}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </div>
              <div>
                {currentCoordinates && (
                  <div className="testing" style={{ width: '100%', height: '100%' }}>
                    {renderMap()}
                  </div>
                )}
              </div>
              <div>
                {distance !== null && (
                  <div style={{ backgroundColor: 'white', padding: '5px', width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <label style={{ color: 'blue', fontWeight: '600' }}>Distance : {distance && distance?.rows[0]?.elements[0]?.distance?.text}</label>
                  </div>
                )}
              </div>
              <div>
                {distance !== null && (
                  <div style={{ backgroundColor: 'white', padding: '5px', width: '100%', display: 'flex', justifyContent: 'center' }}>
                    {parseInt(distance && distance?.rows[0]?.elements[0]?.distance?.value) >= 10000 ? (
                      <label style={{ color: 'red', fontWeight: '600' }}>Currently not delivering to this address</label>
                    ) : (
                      <button onClick={confirmLocation} className="confirmLocation">
                        Confirm Location
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GoogleMapPage;