import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo1 from "../../Assets/Mothers hut logo.png"
import MyContext from "../MyContext";

const MobilSideBar = ({ showSidebar, handleClick }) => {

  const { setMyAccount, myAccount, signupshow } = useContext(MyContext)
  const initialClass1 = "";
  const additionalClass1 = showSidebar ? "sidebarback" : "";
  const navigate = useNavigate();


  const myaccountmodal = async (value) => {
    const userLogin = localStorage.getItem("accountLogin");
    const userArray = userLogin ? JSON.parse(userLogin) : null;
  
    if (userArray) {
      // Use the value directly instead of relying on the state variable
      navigate(`/MyAccountPage?${value}`);
      handleClick();
    } else {
      // UserArray is not present, continue with your existing logic
      setMyAccount(!myAccount);
      document.body.style.overflow = myAccount ? "auto" : "hidden";
      signupshow();
      handleClick();
    }
  };
  


  const initialClass = "mobile-sidebar";
  const additionalClass = showSidebar ? "show" : "";

  const combinedClasses = `${initialClass} ${additionalClass}`.trim();
  const combinedClasses1 = `${initialClass1} ${additionalClass1}`.trim();
  return (
    <div onClick={handleClick} className={combinedClasses1}>
      <div className={combinedClasses}>
        <i onClick={handleClick} className="fa-solid fa-xmark navbar-close-btn"></i>

        <div className="sidebar-link-box">
          <div className="sidebar-logo">
            <img style={{ height: "45px", marginRight: "10px" }} src={logo1} alt="" />

            Mother's hut
          </div>

          <Link className="navbar-option-link-txt" onClick={handleClick} to="/">
            <i className="sidebar-icon-link fa-solid fa-house"></i> Homepage
          </Link>
          <Link
            className="navbar-option-link-txt"
            onClick={handleClick}
            to="/OurMenu"
          >
            <i className="sidebar-icon-link fa-solid fa-list"></i> Our Menu
          </Link>
          <Link
            className="navbar-option-link-txt"
            onClick={handleClick}
            to="/MyFavorites"
          >
            <i className="sidebar-icon-link fa-regular fa-heart"></i> My
            Favorites
          </Link>
          <Link
            className="navbar-option-link-txt"
            onClick={handleClick}
            to="/MyCart"
          >
            <i className="sidebar-icon-link fa-solid fa-cart-shopping"></i> My
            Cart
          </Link>
          <div
            className="navbar-option-link-txt"
            onClick={() => myaccountmodal("MyOrder")}

          >
            <i className="sidebar-icon-link fa-solid fa-bars-staggered"></i> My
            Order
          </div>

          <div
            className="navbar-option-link-txt"
            onClick={() => myaccountmodal("MyAccount")}
          >
            <i className="fa-solid fa-user sidebar-icon-link "></i> My
            Account
          </div>

          <Link
            className="navbar-option-link-txt"
            onClick={handleClick}
            to="/MyCart"
          >
            <i className="sidebar-icon-link fa-brands fa-instagram"></i>{" "}
            Instagram
          </Link>
          <Link
            className="navbar-option-link-txt"
            onClick={handleClick}
            to="https://www.facebook.com/MothersHut/?ref=br_rs"
          >
            <i className="sidebar-icon-link fa-brands fa-facebook-f"></i>{" "}
            facebook
          </Link>
          <Link
            className="navbar-option-link-txt"
            onClick={handleClick}
            to="/MyCart"
          >
            <i
              className="sidebar-icon-link fa-brands fa-youtube"
              onClick={handleClick}
            ></i>
            Youtube
          </Link>
          <Link
            className="navbar-option-link-txt"
            onClick={handleClick}
            to="/termsandconditions"
          >
            <i
              className="sidebar-icon-link fa-brands fa-youtube"
              onClick={handleClick}
            ></i>
            Terms and Conditions
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MobilSideBar;
