import axios from "../axiosConfig"; // Adjust the import path accordingly

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const SMS = {
    
    SendSMS: async (AccountData , saveOrder ,CompanyDetail) => {
      console.log(CompanyDetail)
      console.log(AccountData)
      console.log(saveOrder)
      const orderaData = saveOrder.result
      console.log(orderaData.orderNo)
        try {
          const apiUrl = `${WebApiUrl}/api/SMS/send-sms`;
          const response = await axios.post(
            apiUrl,
            {
                to: AccountData.mobileNo,
                // message: "Dear guest,your order ${saveOrder.orderId} is completed. Click for bill http://mothershut.com/MothersHut_app/files/printReceiptPage.html?orderId=MTAwNDU1MA==. MOTHERSHUT",
                message: `Dear guest, your order ${orderaData.orderNo} is completed. Click for bill http://mothershut.com/MothersHut_app/files/printReceiptPage.html?orderId=${orderaData.orderNo}. ${CompanyDetail.name}`,
                smsTempAttr: "tempid",
                smsTempVal: "1407163559020986744"
            },
            {
              withCredentials: true,
              credentials: "include",
            }
          );
          console.log("Response:", response.data);
          return true;
          // setMainDataCallback(response.data.result);
        } catch (error) {
          console.error("Error:", error.message);
        }
      },
    


};

export default SMS;