import React, { useContext, useEffect, useState } from "react";
import VariableProductModal from "./VariableProductModal";
import MyContext from "./MyContext";
import ItemMasterApi from "../API/API_SERVISES/ItemMaster";
import { format } from "date-fns";
import Order from "../API/API_SERVISES/Order";
import useIntersectionObserver from "./useIntersectionObserver "

const ProductCardMenu = (props) => {
  const {
    product,
    onQuantityChange,
    onFavChange,
    onFavChangefalse,
    cartData,
    imageBaseUrl,
    addingItemInCart,
    RemoveItemInCart,
    productlist1,
    AddToCart,
    RemoveFromCart,

  } = props;
  // console.log(product)

  const [imageSrc, setImageSrc] = useState('');

  const loadImage = (element) => {
    if (product && product.images) {
      setImageSrc(`${imageBaseUrl}${product.images}`);
    }
  };

  const imageRef = useIntersectionObserver(loadImage, { threshold: 0.1 });

  const { selectedService, wishList, loginData, myaccountmodal, buttonClickCounts, setButtonClickCounts, fetchDataWish } = useContext(MyContext);
  const [VariableItemData, setVariableItemData] = useState(false);
  const [parantData, setParantData] = useState();
  const [DataToSend, setDataToSend] = useState({});

  const OpenVariableitem = (productId) => {
    const filteredArray = productlist1.filter(
      (item) => productId === item.attributeParentId
    );
    const parantData = productlist1.find((item) => productId === item.d2Id);

    setDataToSend(filteredArray);
    setParantData(parantData);
    setVariableItemData(true);
  };


  const [itemName, setItemName] = useState();
  const [showToast, setShowToast] = useState(false);
  const [showToast1, setShowToast1] = useState(false);

  const handleButtonClick = () => {
    setShowToast(true);

    setTimeout(() => {
      setShowToast(false);
    }, 500);
  };

  const handleButtonClick1 = () => {
    setShowToast1(true);

    setTimeout(() => {
      setShowToast1(false);
    }, 500);
  };

  const isDateWithinRange = (availableStartDate, availableEndDate) => {
    const currentDate = new Date();
    const startDate = new Date(availableStartDate);
    const endDate = new Date(availableEndDate);
    return currentDate >= startDate && currentDate <= endDate;
  };

  const isTimeWithinRange = (availableTimeFrom, availableTimeTo) => {
    const currentTime = new Date().toLocaleTimeString('en-US', { hour12: false });
    return currentTime >= availableTimeFrom && currentTime <= availableTimeTo;
  };





  const handleIncrementValue = (id, Item, isAttrbute, ParentId, product) => {
    debugger
    var carditem = cartData.find(item => (item.d2ItemId === id))
    if (carditem?.quantity >= product.restoAvailableQuantity && product.stockCheck === 1) {
      alert(`Out of Stock Only ${product.restoAvailableQuantity} Quantity available in stock`);

    }
    else {

      const parantData = productlist1.find((item) => id === item.d2Id);
      if (parantData.attributeParentId === 0 &&
        parantData.isAttributeItem === true) {
        OpenVariableitem(id);

      } else {
        const currentCount = buttonClickCounts[id] || 0;
        const currentCount1 = ParentId !== 0 ? buttonClickCounts[ParentId] || 0 : null;

        // Prepare the updated state object
        const updatedCounts = {
          ...buttonClickCounts,
          [id]: currentCount + 1,
        };

        // Only add ParentId if it's not 0
        if (ParentId !== 0) {
          updatedCounts[ParentId] = currentCount1 + 1;
        }

        // Update the state with new counts
        setButtonClickCounts(updatedCounts);

        // Set the item name and handle other button click related functionality
        setItemName(Item);
        handleButtonClick();
        // variableClose(); // Uncomment or adjust if this needs to be called
        AddToCart(product)
      }
    }

  }


  const handleDecrementValue = (id, itemName, ParentId, product) => {
    RemoveFromCart(product);

    const currentCount = buttonClickCounts[id] || 0;
    let newButtonClickCounts = { ...buttonClickCounts };

    // Decrease count for id
    if (currentCount > 0) {
      newButtonClickCounts[id] = currentCount - 1;
    }

    // Decrease count for ParentId if it's not 0 or null
    if (currentCount > 0) {
      if (ParentId !== 0 && ParentId !== null) {
        const currentParentCount = buttonClickCounts[ParentId] || 0;
        if (currentParentCount > 0) {
          newButtonClickCounts[ParentId] = currentParentCount - 1;
        }
      }
      setItemName(itemName);
      handleButtonClick1();
    }

    // Update state with the new counts
    setButtonClickCounts(newButtonClickCounts);
  };


  useEffect(() => {
    if (cartData === undefined) {
      // // // console.log("if");
      localStorage.setItem("cartData", "");
    } else {
      localStorage.setItem("cartData", JSON.stringify(cartData));
    }
  }, [cartData]);

  const handleIncrement1 = (productId, currentQuantity) => {
    const updatedQuantity = Number(currentQuantity) + 1;
    onQuantityChange(productId, updatedQuantity);
    setVariableItemData(false);
  };

  const variableClose = () => {
    setVariableItemData(false);
  };

  const [, setInputValues] = useState({
    d1ItemId: "",
    d2ItemId: "",
    d1AccountId: "",
    d2AccountId: "",
    userId: "",
  });


  const toggleWishlist = async (itemData) => {
    debugger

    if (loginData) {
      if (loginData.d1Id != undefined) {
        const existingIndex = wishList && wishList.find(product => product.d1ItemId === itemData.d1Id);
        console.log(existingIndex);
        if (existingIndex !== undefined) {
          // alert("Item is already in wishlist");
          try {
            const val = await Order.deleteDeleteFavouriteItemList(existingIndex.id);
            if (Boolean(val) === true) {
              // alert("Delete data successfully", "Ok");
              fetchDataWish()
            } else {
              alert("Error Deleting Data");
            }
            // fetchData();
          } catch (error) {
            console.error("Error deleting account:", error);
          }
          return;
        } else {
          const data = {
            d1ItemId: itemData.d1Id,
            d2ItemId: itemData.d2Id,
            d1AccountId: loginData.d1Id,
            d2AccountId: loginData.d2Id,
            userId: 1,
          };
          try {
            const isSuccess = await Order.insertCreateFavouriteItemList(data);
            if (isSuccess) {
              // alert("Added to wishlist successfully");
              fetchDataWish()
            } else {
              throw new Error("Error while adding to wishlist");
            }
          } catch (error) {
            console.error("Error while adding to wishlist:", error);
            alert("Error while adding to wishlist");
          }
          setInputValues({
            d1ItemId: "",
            d2ItemId: "",
            d1AccountId: "",
            d2AccountId: "",
            userId: "",
          });

        }
      }
      else {
        myaccountmodal();
      }
    }
    else {
      myaccountmodal();
    }
  }

  const currentTime = new Date(); // Current time

  const isAvailableNow = product.restoAvailableTime.some(timeSlot => {
    // Check if both availableTimefrom and availableTimeTo are null
    if (timeSlot.availableTimefrom === null && timeSlot.availableTimeTo === null) {
      return true; // Consider the product as available
    } else {
      return isTimeWithinRange(timeSlot.availableTimefrom, timeSlot.availableTimeTo, currentTime);
    }
  });

  // console.log(isAvailableNow, product.name);`  

  return (
    <>
      <div
        className="card-menu"
        style={{
          opacity:
            selectedService == 751
              ? (product.availableForTableOrder === false ||
                product.restoAvailableQuantity === cartData.find(
                  item => item.d2ItemId === product.d2Id || item.d2ItemId === product.d2ItemId
                )?.quantity ||
                (product.restoAvailableQuantity === 0 && product.stockCheck === 1)
              )
                ? "0.5"
                : isAvailableNow
                  ? "1"
                  : "0.5"
              : selectedService == 752
                ? (product.availableForHomeDelivery === false ||
                  product.restoAvailableQuantity === cartData.find(
                    item => item.d2ItemId === product.d2Id || item.d2ItemId === product.d2ItemId
                  )?.quantity ||
                  (product.restoAvailableQuantity === 0 && product.stockCheck === 1)
                )
                  ? "0.5"
                  : isAvailableNow
                    ? "1"
                    : "0.5"
                : selectedService == 753
                  ? (product.restoAvailableQuantity === cartData.find(
                    item => item.d2ItemId === product.d2Id || item.d2ItemId === product.d2ItemId
                  )?.quantity ||
                    (product.restoAvailableQuantity === 0 && product.stockCheck === 1)
                  )
                    ? "0.5"
                    : isAvailableNow
                      ? "1"
                      : "0.5"
                  : "1" // Default opacity if none of the service types match
        }}
      >
        {(wishList && wishList.find(product1 => product1.d1ItemId === product.d1Id)) ? (
          <i
            style={{ color: "red", cursor: "pointer" }}
            onClick={() => toggleWishlist(product)}
            className="fa-solid fa-heart card-menu-favorite"
          ></i>
        ) : (
          <i
            style={{ color: "black", cursor: "pointer" }}
            onClick={() => toggleWishlist(product)}
            className="fa-regular fa-heart card-menu-favorite"
          ></i>
        )}
        <div className="card-menu-1">
          <div className="menu-image-box">
            <img
              ref={imageRef}
              className="card-menu-image"
              src={imageSrc}
              alt=""
            />
          </div>
          <div className="menu-image-box-1">
            <div>
              <div className="">
                <p className="menu-card-title-name">{product == null ? "" : product.name}</p>
              </div>
              <div className="">
                {/* <p className="menu-card-detail">{product == null ? "" : product.name}</p> */}
              </div>
              <div className="">
                <p className="menu-card-price">
                  price : <i
                    style={{ marginRight: "3px" }}
                    className="fa-solid fa-indian-rupee-sign rupees-icon"
                  ></i>
                  {product == null ? "" : product.salePrice}
                </p>
              </div>
            </div>
            <div>
              {/* table order */}
              {selectedService == "751" && (
                (
                  <>
                    {product == null ? "" : product.restoAvailableQuantity === 0 && product.stockCheck === 1 ? (
                      <p className="ourmenu-productnotavli">Out Of stock</p>
                    )
                      :
                      (
                        <>
                          {product.restoAvailableTime || product.restoAvailableTime.length > 0 || product.availableForTableOrder === true ?
                            (
                              <>
                                {product.restoAvailableTime.some((timeSlot) => {
                                  // Check if both availableTimefrom and availableTimeTo are null
                                  if (timeSlot.availableTimefrom === null && timeSlot.availableTimeTo === null) {
                                    return true; // Consider product available if both are null
                                  }
                                  // Otherwise, check if availableTimefrom and availableTimeTo are defined and within range
                                  return timeSlot.availableTimefrom && timeSlot.availableTimeTo && isTimeWithinRange(timeSlot.availableTimefrom, timeSlot.availableTimeTo);
                                }) ?
                                  (
                                    product.restoAvailableTime.map((timeSlot, index) => (
                                      <span key={index}>
                                        {timeSlot.availableTimefrom && timeSlot.availableTimeTo ? (
                                          <p className="menu-card-price">
                                            {isTimeWithinRange(timeSlot.availableTimefrom, timeSlot.availableTimeTo) ? (
                                              <>
                                                {product != null && product.availableForTableOrder === true && (
                                                  <div className="quantity-box">
                                                    <p className="menu-card-price">
                                                      <div className="quantity">
                                                        <button
                                                          style={{ paddingBottom: "4px" }}
                                                          className="quantity__minus"
                                                          onClick={() => handleDecrementValue(product.d2Id, product.name, product.attributeParentId, product)}
                                                        >
                                                          -
                                                        </button>
                                                        <input
                                                          className="quantity__input"
                                                          type="text"
                                                          value={buttonClickCounts[product.d2Id] || 0}
                                                          readOnly
                                                        />
                                                        <button
                                                          className="quantity__plus"
                                                          onClick={() => handleIncrementValue(product.d2Id, product.name, product.isAttributeItem, product.attributeParentId, product)}
                                                        >
                                                          +
                                                        </button>
                                                      </div>
                                                      {product.isAttributeItem ? (
                                                        <span
                                                          style={{
                                                            marginLeft: "8px",
                                                            marginTop: "2px",
                                                            fontSize: "10px",
                                                            color: "gray",
                                                          }}
                                                          className="menu-card-detail"
                                                        >
                                                          Customisable
                                                        </span>
                                                      ) : (
                                                        <span></span>
                                                      )}
                                                    </p>
                                                    {product.restoAvailableQuantity === cartData.find(item1 =>
                                                      (item1.d2ItemId === product.d2Id || item1.d2ItemId === product.d2ItemId)
                                                    )?.quantity ? (
                                                      <p className="ourmenu-productnotavli">Out Of stock</p>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </div>
                                                )}
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </p>
                                        ) : (
                                          <div className="quantity-box">
                                            <p className="menu-card-price">
                                              <div className="quantity">
                                                <button
                                                  style={{ paddingBottom: "4px" }}
                                                  className="quantity__minus"
                                                  onClick={() => handleDecrementValue(product.d2Id, product.name, product.attributeParentId, product)}
                                                >
                                                  -
                                                </button>
                                                <input
                                                  className="quantity__input"
                                                  type="text"
                                                  value={buttonClickCounts[product.d2Id] || 0}
                                                  readOnly
                                                />
                                                <button
                                                  className="quantity__plus"
                                                  onClick={() => handleIncrementValue(product.d2Id, product.name, product.isAttributeItem, product.attributeParentId, product)}
                                                >
                                                  +
                                                </button>
                                              </div>
                                              {product.isAttributeItem ? (
                                                <span
                                                  style={{
                                                    marginLeft: "8px",
                                                    marginTop: "2px",
                                                    fontSize: "10px",
                                                    color: "gray",
                                                  }}
                                                  className="menu-card-detail"
                                                >
                                                  Customisable
                                                </span>
                                              ) : (
                                                <span></span>
                                              )}
                                            </p>
                                            {product.restoAvailableQuantity === cartData.find(item1 =>
                                              (item1.d2ItemId === product.d2Id || item1.d2ItemId === product.d2ItemId)
                                            )?.quantity ? (
                                              <p className="ourmenu-productnotavli">Out Of stock</p>
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                        )}
                                      </span>
                                    ))
                                  ) : (
                                    <p className="menu-card-price" style={{ color: 'red', marginBottom: "4px" }}>Not Available Now</p>
                                  )}

                              </>
                            ) : (
                              <></>
                            )}

                          {product.restoAvailableStartDate && product.restoAvailableEndDate ? (
                            <p className="menu-card-price">
                              Available Date:
                              {isDateWithinRange(product.restoAvailableStartDate, product.restoAvailableEndDate) ? (
                                <p style={{ color: 'green', marginLeft: '10px' }}>Available Today!</p>
                              ) : (
                                <p style={{ color: 'red', marginLeft: '10px' }}>Not Available Today</p>
                              )}
                            </p>
                          ) : (
                            <></>
                          )}

                          {product != null && product.availableForTableOrder === false && (
                            <p className="ourmenu-productnotavli">Not Available For Table Order</p>
                          )}


                        </>

                      )}

                  </>
                )
              )}

              {/* Home delivery order */}
              {selectedService == "752" && (
                <>
                  {product == null ? "" : product.restoAvailableQuantity === 0 && product.stockCheck === 1 ? (
                    <p className="ourmenu-productnotavli">Out Of stock</p>
                  ) : (
                    <>
                      {product.restoAvailableTime && product.restoAvailableTime.length > 0 && product.availableForHomeDelivery === true ? (
                        <>
                          {product.restoAvailableTime.some((timeSlot) => {
                            const available = timeSlot.availableTimefrom && timeSlot.availableTimeTo;
                            return available ? isTimeWithinRange(timeSlot.availableTimefrom, timeSlot.availableTimeTo) : true;
                          }) ? (
                            product.restoAvailableTime.map((timeSlot, index) => (
                              <span key={index}>
                                {timeSlot.availableTimefrom && timeSlot.availableTimeTo ? (
                                  isTimeWithinRange(timeSlot.availableTimefrom, timeSlot.availableTimeTo) && (
                                    <div className="quantity-box">
                                      <p className="menu-card-price">
                                        <div className="quantity">
                                          <button
                                            style={{ paddingBottom: "4px" }}
                                            className="quantity__minus"
                                            onClick={() => handleDecrementValue(product.d2Id, product.name, product.attributeParentId, product)}
                                          >
                                            -
                                          </button>
                                          <input
                                            className="quantity__input"
                                            type="text"
                                            value={buttonClickCounts[product.d2Id] || 0}
                                            readOnly
                                          />
                                          <button
                                            className="quantity__plus"
                                            onClick={() => handleIncrementValue(product.d2Id, product.name, product.isAttributeItem, product.attributeParentId, product)}
                                          >
                                            +
                                          </button>
                                        </div>
                                        {product.isAttributeItem ? (
                                          <span
                                            style={{
                                              marginLeft: "8px",
                                              marginTop: "2px",
                                              fontSize: "10px",
                                              color: "gray",
                                            }}
                                            className="menu-card-detail"
                                          >
                                            Customisable
                                          </span>
                                        ) : (
                                          <span></span>
                                        )}
                                      </p>
                                      {product.restoAvailableQuantity === cartData.find(item1 =>
                                        (item1.d2ItemId === product.d2Id || item1.d2ItemId === product.d2ItemId)
                                      )?.quantity ? (
                                        <p className="ourmenu-productnotavli">Out Of stock</p>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  )
                                ) : (
                                  <div className="quantity-box">
                                    <p className="menu-card-price">
                                      <div className="quantity">
                                        <button
                                          style={{ paddingBottom: "4px" }}
                                          className="quantity__minus"
                                          onClick={() => handleDecrementValue(product.d2Id, product.name, product.attributeParentId, product)}
                                        >
                                          -
                                        </button>
                                        <input
                                          className="quantity__input"
                                          type="text"
                                          value={buttonClickCounts[product.d2Id] || 0}
                                          readOnly
                                        />
                                        <button
                                          className="quantity__plus"
                                          onClick={() => handleIncrementValue(product.d2Id, product.name, product.isAttributeItem, product.attributeParentId, product)}
                                        >
                                          +
                                        </button>
                                      </div>
                                      {product.isAttributeItem ? (
                                        <span
                                          style={{
                                            marginLeft: "8px",
                                            marginTop: "2px",
                                            fontSize: "10px",
                                            color: "gray",
                                          }}
                                          className="menu-card-detail"
                                        >
                                          Customisable
                                        </span>
                                      ) : (
                                        <span></span>
                                      )}
                                    </p>
                                    {product.restoAvailableQuantity === cartData.find(item1 =>
                                      (item1.d2ItemId === product.d2Id || item1.d2ItemId === product.d2ItemId)
                                    )?.quantity ? (
                                      <p className="ourmenu-productnotavli">Out Of stock</p>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                )}
                              </span>
                            ))
                          ) : (
                            <p className="menu-card-price" style={{ color: 'red', marginBottom: "4px" }}>Not Available Now</p>
                          )}
                        </>
                      ) : (
                        <></>
                      )}

                      {product.restoAvailableStartDate && product.restoAvailableEndDate ? (
                        <p className="menu-card-price">
                          Available Date:
                          {isDateWithinRange(product.restoAvailableStartDate, product.restoAvailableEndDate) ? (
                            <p style={{ color: 'green', marginLeft: '10px' }}>Available Today!</p>
                          ) : (
                            <p style={{ color: 'red', marginLeft: '10px' }}>Not Available Today</p>
                          )}
                        </p>
                      ) : (
                        <></>
                      )}

                      {product != null && product.availableForHomeDelivery === false && (
                        <p className="ourmenu-productnotavli">Not Available For Home Delivery</p>
                      )}
                    </>
                  )}
                </>
              )}

              {/* Pick Up order */}
              {selectedService == "753" && (
                <>
                  {product == null ? "" : product.restoAvailableQuantity === 0 && product.stockCheck === 1 ? (
                    <p className="ourmenu-productnotavli">Out Of stock</p>
                  ) : (
                    <>
                      {product.restoAvailableTime && product.restoAvailableTime.length > 0 ? (
                        <>
                          {product.restoAvailableTime.some((timeSlot) => {
                            const available = timeSlot.availableTimefrom && timeSlot.availableTimeTo;
                            return available ? isTimeWithinRange(timeSlot.availableTimefrom, timeSlot.availableTimeTo) : true;
                          }) ? (
                            product.restoAvailableTime.map((timeSlot, index) => (
                              <span key={index}>
                                {timeSlot.availableTimefrom && timeSlot.availableTimeTo ? (
                                  isTimeWithinRange(timeSlot.availableTimefrom, timeSlot.availableTimeTo) && (
                                    <div className="quantity-box">
                                      <p className="menu-card-price">
                                        <div className="quantity">
                                          <button
                                            style={{ paddingBottom: "4px" }}
                                            className="quantity__minus"
                                            onClick={() => handleDecrementValue(product.d2Id, product.name, product.attributeParentId, product)}
                                          >
                                            -
                                          </button>
                                          <input
                                            className="quantity__input"
                                            type="text"
                                            value={buttonClickCounts[product.d2Id] || 0}
                                            readOnly
                                          />
                                          <button
                                            className="quantity__plus"
                                            onClick={() => handleIncrementValue(product.d2Id, product.name, product.isAttributeItem, product.attributeParentId, product)}
                                          >
                                            +
                                          </button>
                                        </div>
                                        {product.isAttributeItem ? (
                                          <span
                                            style={{
                                              marginLeft: "8px",
                                              marginTop: "2px",
                                              fontSize: "10px",
                                              color: "gray",
                                            }}
                                            className="menu-card-detail"
                                          >
                                            Customisable
                                          </span>
                                        ) : (
                                          <span></span>
                                        )}
                                      </p>
                                      {product.restoAvailableQuantity === cartData.find(item1 =>
                                        (item1.d2ItemId === product.d2Id || item1.d2ItemId === product.d2ItemId)
                                      )?.quantity ? (
                                        <p className="ourmenu-productnotavli">Out Of stock</p>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  )
                                ) : (
                                  <div className="quantity-box">
                                    <p className="menu-card-price">
                                      <div className="quantity">
                                        <button
                                          style={{ paddingBottom: "4px" }}
                                          className="quantity__minus"
                                          onClick={() => handleDecrementValue(product.d2Id, product.name, product.attributeParentId, product)}
                                        >
                                          -
                                        </button>
                                        <input
                                          className="quantity__input"
                                          type="text"
                                          value={buttonClickCounts[product.d2Id] || 0}
                                          readOnly
                                        />
                                        <button
                                          className="quantity__plus"
                                          onClick={() => handleIncrementValue(product.d2Id, product.name, product.isAttributeItem, product.attributeParentId, product)}
                                        >
                                          +
                                        </button>
                                      </div>
                                      {product.isAttributeItem ? (
                                        <span
                                          style={{
                                            marginLeft: "8px",
                                            marginTop: "2px",
                                            fontSize: "10px",
                                            color: "gray",
                                          }}
                                          className="menu-card-detail"
                                        >
                                          Customisable
                                        </span>
                                      ) : (
                                        <span></span>
                                      )}
                                    </p>
                                    {product.restoAvailableQuantity === cartData.find(item1 =>
                                      (item1.d2ItemId === product.d2Id || item1.d2ItemId === product.d2ItemId)
                                    )?.quantity ? (
                                      <p className="ourmenu-productnotavli">Out Of stock</p>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                )}
                              </span>
                            ))
                          ) : (
                            <p className="menu-card-price" style={{ color: 'red', marginBottom: "4px" }}>Not Available Now</p>
                          )}
                        </>
                      ) : (
                        <></>
                      )}

                      {product.restoAvailableStartDate && product.restoAvailableEndDate ? (
                        <p className="menu-card-price">
                          Available Date:
                          {isDateWithinRange(product.restoAvailableStartDate, product.restoAvailableEndDate) ? (
                            <p style={{ color: 'green', marginLeft: '10px' }}>Available Today!</p>
                          ) : (
                            <p style={{ color: 'red', marginLeft: '10px' }}>Not Available Today</p>
                          )}
                        </p>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </>
              )}



            </div>
          </div>
        </div>
        {/* } else {
          console.error("Product or product images are undefined.")
        } */}
      </div>
      {showToast && (
        <div className="toastDiv">{itemName} Added Successfully !!</div>
      )}
      {showToast1 && (
        <div className="toastDiv">Removed {itemName} !!</div>
      )}
      {VariableItemData && (
        <div className="variableproduct-modal-back">
          <VariableProductModal
            parantData={parantData}
            product={product == null ? "" : product}
            data={DataToSend}
            onClick={OpenVariableitem}
            RemoveFromCart={RemoveFromCart}
            AddToCart={AddToCart}
            handleIncrementValue={handleIncrementValue}
            variableClose={variableClose}
            handleDecrementValue={handleDecrementValue}
            buttonClickCounts={buttonClickCounts}
            cartData={cartData}
            selectedService={selectedService}
            isDateWithinRange={isDateWithinRange}
            isTimeWithinRange={isTimeWithinRange}
          />
        </div>
      )}
    </>
  );
};

export default ProductCardMenu;