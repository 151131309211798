import React, { useContext, useState } from "react";
import Loader from "../Component/Loader";
import MyContext from "../Component/MyContext";
import Order from "../API/API_SERVISES/Order";
import noproductfound1 from "../Assets/empty-cart.png"



const MyFavorites = () => {
  const [loading, setLoading] = useState(false);

  const { wishList, imageBaseUrl, loginData ,fetchDataWish ,AddToCart } = useContext(MyContext)

const deleteFavourite= async(id)=>{
  try {
    const val = await Order.deleteDeleteFavouriteItemList(id);
    if (Boolean(val) === true) {
      // alert("Delete data successfully", "Ok");
      fetchDataWish()
    } else {
      alert("Error Deleting Data");
    }
    // fetchData();
  } catch (error) {
    console.error("Error deleting account:", error);
  }
}





  return (
    <div>
      <div className="product-category-head">
        My Favourite
        <i
          style={{ color: "white", marginLeft: "5px" }}
          className="fa-regular fa-heart"
        ></i>
      </div>

      <div className="master-container">
        <div
          style={{ margin: "10px auto" , padding:"10px 0px", borderRadius: "15px", height: "max-content" , minHeight:"70vh" }}
          className="card-cart cart">
          <label
            className="title"
            style={{
              borderBottom: "1px solid rgb(229, 229, 229)",
              paddingBottom: "5px",
              marginBottom: "15px",
            }}
          >
            My Favourite{" "}
          </label>
          {wishList.length === 0 ? (
            <div style={{display:"flex", alignItems:"center" , flexDirection:"column"}}>
            <img style={{width:"30%"}} src={noproductfound1} alt="" />
            {/* <img style={{width:"40%"}} src={noproductfound} alt="" /> */}
            <h6 className="nofavproduct">No products found  </h6>
            {/* <h3 className="nofavproduct">Please add some products</h3>   */}
            </div>
          ) : (
            wishList.filter((product) => product.d2AccountId === loginData.d2Id).map((product) => (
              <div key={product.id} className="products">
                <div className="product">
                  <div className="products-left">
                    <img
                      style={{ borderRadius: "8px" }}
                      className="image-for-big"
                      src={`${imageBaseUrl}${product.itemImgId || 0}`}
                      alt=""

                    />
                  </div>
                  <div className="products-right">
                    <div>
                      <span style={{marginBottom:"0px" ,fontSize:"14px"}}>{product.itemName}</span>
                      <p className="InnerPTag" dangerouslySetInnerHTML={{ __html: product.shortDescription }} />
                    </div>
                <div  className="products-right-small">

                    <i onClick={()=>AddToCart(product)} className="fa-solid fa-cart-shopping favourite-add"></i>
                    <i
                      style={{
                        color: "#ef2626",
                        cursor: "pointer",
                      }}
                      onClick={()=> deleteFavourite(product.id)}
                      className="fa-solid fa-trash favourite-delete"
                    ></i>
                    <label className="price small">
                      {product.itemPrice}
                      <i
                        style={{ marginLeft: "3px" }}
                        className="fa-solid fa-indian-rupee-sign"
                      ></i>
                    </label>
                </div>

                  </div>
                </div>
              </div>
            )))}
        </div>
      </div>

      {/* <table  className='favroit-table'>
        <tr>
        <th>sno</th>
        <th>Product Name</th>
        <th>Product Price </th>
        <th>Date Added</th>
        <th>Add To Cart</th>
        <th>Delete</th>
        </tr>
          
          {filteredProducts.map(product =>(
            <tr>
          <td> <img style={{width:"40px" , marginTop:"7px",   borderRadius:"8px"}} src={product.image} alt={product.name} /></td>
          <td> <p className='myfav-txt' style={{margin:"0"}}>{product.name}</p></td>
          <td> <p className='myfav-txt' style={{margin:"0"}}> {product.price} <i className="fa-solid fa-indian-rupee-sign"></i></p></td>
          <td> <p className='myfav-txt' style={{margin:"0"}}>{product.description}</p>  </td>
          <td style={{width:"25%"}}> <button className='add-to-cart-button-fav'>Add To Cart</button></td>
          <td> <i style={{color:"#f11818"}} className='fa-solid fa-trash'></i></td>
        </tr>
          ))}
      </table> */}
      {loading && <Loader />}

    </div>
  );
};

export default MyFavorites;
