import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ShopBanner from '../API/API_SERVISES/Shopbanner';
import MyContext from './MyContext';

const ImageSlider = () => {
  const { imageBaseUrl, currentUser } = useContext(MyContext);
  const [bannerImages, setBannerImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const navigate = useNavigate();
  // alert(currentUser)
  useEffect(() => {
    const fetchData = async () => {
      if (currentUser) {
        try {
          const data = await ShopBanner.GetShopBanners();
          const sortedData = data.slice().sort((a, b) => a.bannerPriority - b.bannerPriority);
          setBannerImages(sortedData);
        } catch (error) {
          console.error('Error fetching banner images:', error);
        }
      }
    };
    fetchData();
  }, [currentUser]);

  const nextSlide = (e) => {
    //if (e) e.preventDefault();
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % bannerImages.length);
  };

  const previousSlide = (e) => {
    //if (e) e.preventDefault();
    setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? bannerImages.length - 1 : prevIndex - 1));
  };

  useEffect(() => {
    const timer = setInterval(nextSlide, 3000);
    return () => clearInterval(timer);
  }, [bannerImages.length]);

  if (bannerImages.length === 0) {
    return <div>Loading...</div>;
  }

  const backgroundImageStyle = {
    backgroundImage: `url(${imageBaseUrl}${bannerImages && bannerImages != null && bannerImages != undefined && bannerImages.length > 0 ? bannerImages[currentImageIndex]?.bannerImageId : 0})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    // height: '400px',
    cursor: 'pointer'
  };
  // console.log(`test ${bannerImages[currentImageIndex].redirectTo}`);

  const imageClick = () => {
    const ItemId = bannerImages[currentImageIndex].redirectTo;
    navigate("/ourmenu", {
      state: {
        ItemId
      }
    });
  }
  const handleSlideClick = (e) => {
    e.stopPropagation(); // Prevent bubbling to parent div
  };

  return (
    <div className="image-slider" style={backgroundImageStyle} onClick={handleSlideClick}>
      <div className='image-slider-button-box'>
        <button className='image-slider-button' onClick={previousSlide}>
          <i className="fa-solid fa-chevron-left" ></i>
        </button>
        <button className='image-slider-button' onClick={nextSlide}>
          <i className="fa-solid fa-chevron-right" ></i>
        </button>
      </div>
      <div onClick={imageClick} style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, cursor: 'pointer' }}></div>
    </div>
  );
};

export default ImageSlider;
