import React, { useEffect, useState } from "react";
import MyAccountComponent2Address from "./MyAccountComponent2Address";

const TableAndAddress = (props) => {
  const {
    AllottedTimeSlot,
    dateOptions,
    openaddress,
    serviceType,
    ChangeDeliveryDate,
    inputValues,
    CompanyDetail,
    isInputDisabled,
    tableGroup,
    tableNumberData,
    onTableSelect,
    onFloorSelect,
    ChangeDeliveryTime,
    totalAmount,
    CloseTable,
    GoToPayment,
    selectedService,
  } = props;



  //console.log(selectedService)

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 800);

  function convertTo12HourFormat(twentyFourHourTime) {
    const [hours, minutes] = twentyFourHourTime.split(':').map(Number);

    let period = 'AM';
    let twelveHourHours = hours;

    if (hours >= 12) {
      period = 'PM';
      if (hours > 12) {
        twelveHourHours -= 12;
      }
    }

    if (twelveHourHours === 0) {
      twelveHourHours = 12;
    }
    if (minutes != undefined && minutes != null) {
      const formattedMinutes = minutes === 0 ? '00' : minutes.toString().padStart(2, '0');
      return `${twelveHourHours.toString().padStart(2, '0')}:${formattedMinutes} ${period}`;
    }
    else {
      return `${twelveHourHours.toString().padStart(2, '0')}: 00 ${period}`;
    }
  }

  // Effect to add window resize listener
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 800);
    };

    // Set up the event listener
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [isScreenLarge, setIsScreenLarge] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsScreenLarge(window.innerWidth < 800);
    };
    window.addEventListener('resize', handleResize);
    // Cleanup function to remove the event listener when component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);

    };
  }, []);

  const [isFromOrderTkr, setIsFromOrderTkr] = useState();

  useEffect(() => {
    var isFromOrderTak = localStorage.getItem("OrderTaker");
    setIsFromOrderTkr(isFromOrderTak);
  }, [])

  function convertTimeTo12HourFormat(time24) {
    const [hours, minutes, seconds] = time24.split(':');
    
    const date = new Date();
    date.setHours(hours, minutes, seconds || 0); 
    
    const time12 = date.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });
    return time12;
  }

  return (
    <div className={isSmallScreen ? 'modal-back' : ''}>
      {/* {serviceType[1]?.id == inputValues.serviceTypeId && ( */}
      {selectedService == 752 && (
        <div
          className="cartpage-right-section-2"
          style={{ height: "max-content" }}
        >
          <div
            onClick={CloseTable}
            style={{ zIndex: "2", background: "gray", color: "white" }}
            className="xmark-ms-2"
          >
            <i className="fa-solid fa-xmark"></i>
          </div>
          <button
            className="cartpage-right-section-2-button"
            onClick={openaddress}
            style={{ display: "none" }}
          >
            Add Address
          </button>
          <label style={{ border: "0" }} className="title dis-fl-fl-co">
            Delivery address
          </label>
          <p
            style={{ margin: "0", color: "#63656b", fontWeight: "600" }}
            className="f-12"
          >
            Manage multiple delivery address
          </p>
          <div className="delAddressSection">
            <MyAccountComponent2Address />
          </div>
          <div
            className="mycart-table-order mt-2"
            style={{ marginTop: "15px" }}
          >
            <div className="mycart-table-order-row">
              <select name="" id="">
                <option value="">Mothers Hut</option>
              </select>
            </div>
            <div className="mycart-table-order-row">
              <select
                onChange={(e) => ChangeDeliveryDate(e)}
                name="deliveryDate"
                id=""
                value={inputValues.deliveryDate}
              >
                <option value="">Select your date</option>
                {dateOptions ? (
                  dateOptions.map((dateOption, index) => (
                    <option key={index} value={dateOption.formattedDate}>
                      {dateOption.formattedDate}
                    </option>
                  ))
                ) : (
                  <option value="0">No dates available</option>
                )}
              </select>
              <select id=""
                onChange={(e) => ChangeDeliveryTime(e)}
                name="deliveryTimeId"
                disabled={isInputDisabled}
                value={inputValues.deliveryTimeId}>
                {(() => {
                  if (
                    AllottedTimeSlot &&
                    Array.isArray(AllottedTimeSlot) &&
                    AllottedTimeSlot.length > 0
                  ) {
                    return AllottedTimeSlot.map((data, index3) => (
                      <option key={index3} value={data.id}>
                        {convertTo12HourFormat(data.availableFrom)} to {convertTo12HourFormat(data.availableTo)}
                      </option>
                    ));
                  } else {
                    return <option value="">Not available</option>;
                  }
                })()}
              </select>
            </div>
          </div>
          {isScreenLarge && (
            <div
              style={{ margin: "20px 0px", borderRadius: "12px" }}
              className="myordercart-mobile-2"
              onClick={GoToPayment}
            >
              Place Order
              <div>
                <i
                  style={{ marginRight: "5px" }}
                  className="fa-solid fa-indian-rupee-sign"
                ></i>{" "}
                {totalAmount}

              </div>
            </div>
          )}
        </div>
      )}
      {/* {serviceType[2]?.id == inputValues.serviceTypeId && (
         */}
      {selectedService == 753 && (

        <div className="cartpage-right-section-2">
          <div
            onClick={CloseTable}
            style={{ zIndex: "2", background: "gray", color: "white" }}
            className="xmark-ms-2"
          >
            <i className="fa-solid fa-xmark"></i>
          </div>
          <label style={{ border: "0" }} className="title dis-fl-fl-co">
            Pick-up On
          </label>
          <div className="mycart-table-order">
            <div className="mycart-table-order-row">
              <select name="" id="">
                <option value="">Mothers Hut</option>
              </select>
            </div>
            <div className="mycart-table-order-row">
              <select
                onChange={(e) => ChangeDeliveryDate(e)}
                name="deliveryDate"
                id=""
                value={inputValues.deliveryDate}
              >
                <option value="">Select your date</option>
                {dateOptions ? (
                  dateOptions.map((dateOption, index4) => (
                    <option key={index4} value={dateOption.formattedDate}>
                      {dateOption.formattedDate}
                    </option>
                  ))
                ) : (
                  <option value="0">No dates available</option>
                )}
              </select>
              <select
                id=""
                onChange={(e) => ChangeDeliveryTime(e)}
                name="deliveryTimeId"
                disabled={isInputDisabled}
                value={inputValues.deliveryTimeId}
              >
                {(() => {
                  if (
                    AllottedTimeSlot &&
                    Array.isArray(AllottedTimeSlot) &&
                    AllottedTimeSlot.length > 0
                  ) {
                    return AllottedTimeSlot.map((data, index5) => (
                      <option key={index5} value={data.id}>
                        {convertTimeTo12HourFormat(data.availableFrom)} to {convertTimeTo12HourFormat(data.availableTo)}
                      </option>
                    ));
                  } else {
                    return <option value="">Not available</option>;
                  }
                })()}
              </select>
            </div>
          </div>
          {isScreenLarge && (
            <div
              style={{ margin: "20px 0px", borderRadius: "12px" }}
              className="myordercart-mobile-2"
              onClick={GoToPayment}
            >
              Place Order
              <div>
                <i
                  style={{ marginRight: "5px" }}
                  className="fa-solid fa-indian-rupee-sign"
                ></i>{" "}
                {totalAmount}

              </div>
            </div>
          )}
        </div>
      )}
      {/* {serviceType[0]?.id == inputValues.serviceTypeId && ( */}
      {selectedService == 751 && (
        <div className="cartpage-right-section-2">
          {CompanyDetail.isAllowTblOptionForGuest || isFromOrderTkr == "Yes" ? (
            <div>
              <div className="title dis-fl-fl-co">Select your table</div>
              <div className="mycart-table-order">
                <div
                  style={{ marginTop: "10px" }}
                  className="mycart-table-order-row"
                >
                  <select
                    onChange={(event) => onFloorSelect(event.target.value)}
                    value={inputValues.tableGroupId || 0}
                    disabled={isInputDisabled}
                  >
                    <option disabled value="0">Select Floor</option>
                    {(() => {
                      if (
                        tableGroup !== undefined &&
                        Array.isArray(tableGroup) &&
                        tableGroup.length > 0
                      ) {
                        return tableGroup.map((data, index7) => (
                          <option key={index7} value={data.id}>
                            {data.name}
                          </option>
                        ));
                      } else {
                        return <option value="0">No Table Group Found</option>;
                      }
                    })()}
                  </select>
                  <label>:</label>
                  <select
                    onChange={onTableSelect}
                    disabled={isInputDisabled}
                    value={inputValues.tableId ? inputValues.tableId : 0}
                  >
                    <option style={{ appearance: "none", background: "" }} value="0">
                      Select Table No.
                    </option>
                    {(() => {
                      if (
                        tableNumberData !== undefined &&
                        Array.isArray(tableNumberData) &&
                        tableNumberData.length > 0
                      ) {
                        return tableNumberData.map((data, index2) => (
                          //console.log("tableData", data),
                          // <option key={index2} value={data.tableId}>
                          <option key={index2} value={data.tableId}>
                            {data.tableName}
                          </option>
                        ));
                      } else {
                        return <option value="0">No Table found</option>;
                      }
                    })()}
                  </select>
                </div>
              </div>
            </div>
          ) : null}
          <div className="">
            <div
              onClick={CloseTable}
              style={{ zIndex: "2", background: "gray", color: "white" }}
              className="xmark-ms-2"
            >
              <i className="fa-solid fa-xmark"></i>
            </div>
            <label style={{ border: "0" }} className="title dis-fl-fl-co">
              Book On
            </label>

            <div className="mycart-table-order">
              <div className="mycart-table-order-row">
                <select name="" id="" disabled={isInputDisabled}>
                  <option value="">Mothers Hut</option>
                </select>
              </div>
              <div className="mycart-table-order-row">
                {/* <p>{inputValues.deliveryDate}</p> */}
                <select
                  onChange={(e) => ChangeDeliveryDate(e)}
                  name="deliveryDate"
                  id=""
                  disabled={isInputDisabled}
                  value={inputValues.deliveryDate}
                >
                  <option value="">Select your date</option>
                  {dateOptions ? (
                    dateOptions.map((dateOption, index6) => (
                      <option key={index6} value={dateOption.formattedDate}>
                        {dateOption.formattedDate}
                      </option>
                    ))
                  ) : (
                    <option value="0">No dates available</option>
                  )}
                </select>

                <select
                  onChange={(e) => ChangeDeliveryTime(e)}
                  name="deliveryTimeId"
                  id=""
                  disabled={isInputDisabled}
                  value={inputValues.deliveryTimeId}
                >
                  <option value="">Select your time</option>
                  {(() => {
                    if (
                      AllottedTimeSlot &&
                      Array.isArray(AllottedTimeSlot) &&
                      AllottedTimeSlot.length > 0
                    ) {
                      return AllottedTimeSlot.map((data, index1) => (
                        <option key={index1} value={data.id}>
                          {convertTimeTo12HourFormat(data.availableFrom)} to {convertTimeTo12HourFormat(data.availableTo)}
                        </option>
                      ));
                    } else {
                      return <option value="">Not available</option>;
                    }
                  })()}
                </select>
              </div>
            </div>
            {/* ************************** */}
          </div>



          {isScreenLarge && (
            <div
              style={{ margin: "20px 0px", borderRadius: "12px" }}
              className="myordercart-mobile-2"
              onClick={GoToPayment}
            >
              CheckOut
              <div>
                <i
                  style={{ marginRight: "5px" }}
                  className="fa-solid fa-indian-rupee-sign"
                ></i>{" "}
                {totalAmount}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TableAndAddress;
