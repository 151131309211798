import React, { useEffect, useState } from "react";
import LogOut from "./LogOut";
import Account from "../API/API_SERVISES/Account";
import Loader from "./Loader";
import { useNavigate } from "react-router-dom";

const MyAccountComponent = (props) => {

  const navigate = useNavigate();

  const [deletebutton, setDeletebutton] = useState(false);
  const [loading, setLoading] = useState(false);

  const deletemodal = async () => {
    setDeletebutton(!deletebutton); // commented as account delete is not working
    //alert("Currently Unable to Delete Account")
  };
  const closeModal = () => {
    setDeletebutton(false);
  }
  const DeleteAccount = async () => {
    const storedArrayString = localStorage.getItem('accountLogin');
    const loginUser = JSON.parse(storedArrayString);

    await Account.deleteAccount(loginUser.d1Id, loginUser.d2Id);
    localStorage.removeItem("accountLogin");
    localStorage.removeItem("OrderDataToTrack");
    localStorage.removeItem("Address");
    alert("Deleted SuccessFully");
    navigate("/");
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(`Changing ${name} to ${value}`);

    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const [inputValues, setInputValues] = useState({
    name: "",
    mobileNo: "",
    email: "",
    dob: "",
    // password: "",
  });


  // useEffect(() => {
  //   setInputValues({
  //     name: props.accountData.name,
  //     mobileNo: props.accountData.mobileNo,
  //     email: props.accountData.email,
  //     dob: props.accountData.dob,
  //     password: props.accountData.password,
  //   });
  // }, [props.accountData]);

  useEffect(() => {
    // Check if props.accountData exists and has a value
    if (props.accountData && Object.keys(props.accountData).length > 0) {
      setInputValues({
        name: props.accountData.name,
        mobileNo: props.accountData.mobileNo,
        email: props.accountData.email,
        dob: props.accountData.dob,
        password: props.accountData.password,
      });
    }
  }, [props.accountData]);

  const UpdateAccountData = async () => {
    setLoading(true)
    const filteredData = [
      // {
      //   "operationType": 0,
      //   "path": "password",
      //   "op": "Add",
      //   "from": "string",
      //   "value": inputValues.password
      // },
      {
        "operationType": 0,
        "path": "Name",
        "op": "Add",
        "from": "string",
        "value": inputValues.name
      },
      {
        "operationType": 0,
        "path": "AMobileNo",
        "op": "Add",
        "from": "string",
        "value": inputValues.mobileNo
      },
      {
        "operationType": 0,
        "path": "AEmail",
        "op": "Add",
        "from": "string",
        "value": inputValues.email
      },
      {
        "operationType": 0,
        "path": "ADob",
        "op": "Add",
        "from": "string",
        "value": inputValues.dob
      },
    ]
    await Account.PartiallyUpdateAccount(filteredData);
    setLoading(false);
    alert("Updated")
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          // height: "90vh",
          justifyContent: "center",
        }}
        className="master-container"
      >
        <div
          style={{ height: "78vh", borderRadius: "15px" }}
          className="card-cart cart height"
        >
          <label className="title">Personal Info</label>

          <div className="pi-main-box">
            <div className="personal-info-text-box" style={{ display: "flex" }}>
              <div className="fs-13">Name :</div>
              <div className="form1">
                <input
                  className="input1"
                  placeholder="Name"
                  required=""
                  value={inputValues.name}
                  onChange={handleInputChange}
                  type="text"
                  name="name"
                />
                <span className="input-border1"></span>
              </div>
            </div>
            <div className="personal-info-text-box" style={{ display: "flex" }}>
              <div className="fs-13">Mobile No :</div>
              <div className="form1">
                <input
                  className="input1"
                  placeholder="Mobile no"
                  required=""
                  value={inputValues.mobileNo}
                  onChange={handleInputChange}
                  type="number"
                  name="mobileNo"
                />
                <span className="input-border1"></span>
              </div>
            </div>
            <div className="personal-info-text-box" style={{ display: "flex" }}>
              <div className="fs-13">E-Mail :</div>
              <div className="form1">
                <input
                  className="input1"
                  placeholder="E-Mail"
                  required=""
                  value={inputValues.email}
                  onChange={handleInputChange}
                  type="text"
                  name="email"
                />
                <span className="input-border1"></span>
              </div>
            </div>
            <div className="personal-info-text-box" style={{ display: "flex" }}>
              <div className="fs-13">Date of birth :</div>
              <div className="form1">
                <input
                  className="input1"
                  placeholder="Name"
                  required=""
                  type="date"
                  onChange={handleInputChange}
                  value={inputValues.dob}
                  name="dob"
                />
                <span className="input-border1"></span>
              </div>
            </div>
            {/* <div className="personal-info-text-box" style={{ display: "flex" }}>
              <div className="fs-13">Password :</div>
              <div className="form1">
                <input
                  className="input1"
                  placeholder="password"
                  required=""
                  value={inputValues.password}
                  //onChange={handleInputChange}
                  type="password"
                  //name="password"
                />
                <span className="input-border1"></span>
              </div>
            </div> */}
            <div className="button-box-pi">
              <button className="button-pi" onClick={UpdateAccountData}>
                Update
              </button>

              <button
                onClick={deletemodal}
                style={{ background: "rgb(207 12 12)" }}
                className="button-pi"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>

      {deletebutton && (
        <LogOut close={closeModal} logout={DeleteAccount} text="You Want To Delete Your Account" />
      )}
      {loading && <Loader />}
    </div>
  );
};

export default MyAccountComponent;
